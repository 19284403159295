import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import TextSection from "../components/text-section";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

const AboutUsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			TeamImg: wpMediaItem(title: { eq: "Firm photo new" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const TeamImg = data.TeamImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
					id: `${siteUrl}/about-us`,
				},
			},
		],
	};
	const pluginImage = getImage(heroImg);
	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="About Us | Denis Bonnici Electricians Ltd"
				description="Denis Bonnici Electricians Ltd are a small team of Electricians, established in Eastbourne for over 30 years."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us`,
					title: "About Us | Denis Bonnici Electricians Ltd",
					description:
						"Denis Bonnici Electricians Ltd are a small team of Electricians, established in Eastbourne for over 30 years.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@denisbonnici",
					site: "@denisbonnici",
					cardType: "summary",
				}}
			/>
			<BgImage image={pluginImage} alt="Background Image">
				<div
					className="py-md-7 py-4"
					style={{
						background:
							"linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(71, 3, 3, 0.62), rgba(71, 3, 3, 0.62))",

						minHeight: "100%",
					}}
				>
					<TextSection
						sHeading="ABOUT US"
						title="Denis Bonnici Electricians Ltd"
						text={
							<div>
								<div className="w-100 d-flex justify-content-center">
									<GatsbyImage
										className=" mb-3"
										image={TeamImg}
										alt={data.TeamImg?.altText}
									/>
								</div>
								<p>
									Denis Bonnici Electricians Ltd are a small team of
									Electricians, established in Eastbourne for over 30 years. We
									aim to deliver the highest level of service and quality to
									both domestic and commercial clients before, during and after
									work has been carried out.
								</p>
								{/* <p>
									As keyworkers we are still working to keep you safe and
									attending to all of your electrical requirements. Our
									Electricians follow all government guidelines on hygiene and
									social distancing.
								</p> */}
								<p>
									We are fully insured and have public liability cover for
									£5,000,000. We are affiliated with various professional bodies
									who verify our credentials, so that you, the customer, can
									have peace of mind:
								</p>
								<ul className="ps-0">
									<li>
										We are registered and qualified to latest 18th edition
										BS7671 standard. Our work is inspected annually by NIC/EIC
										to maintain our Part P Approved Contractor status.
									</li>
									<li>
										Our membership of the ECA means that our work is covered by
										the ECA Guarantee of Work Scheme.
									</li>
									<li>
										We are qualified IPAF 3a Mobile & Vertical Scissor Lift
										Operators for works required at height.
									</li>
									<li>
										As members of the Buy With Confidence Scheme we are
										independently approved by Trading Standards and are
										committed to operating legally, honestly and fairly.
										Therefore, in the unlikely event that you encounter any
										problems, we will endeavour to fully assist you to satisfy
										our obligations under the Consumer Right Act.
									</li>
									<li>
										We are registered with the Electrical Competent Person
										Register and approved to carry out Electrical Safety
										Reports, Remedials and Installations, and have been
										authorised by Government to self-certify that our work is
										compliant with Building Regulations. This means we meet
										strict entry requirements, our work is regularly assessed,
										demonstrating our ability and ongoing competence, and that
										it meets the correct standards.
									</li>
								</ul>
							</div>
						}
					/>
				</div>
			</BgImage>
		</Layout>
	);
};

export default AboutUsPage;
